import {
  Box,
  Button,
  Card,
  Text,
  Page,
  WixDesignSystemProvider,
  Divider,
  FormField,
  ToggleSwitch,
  CircularProgressBar,
  Layout,
  Cell,
  SegmentedToggle,
  ColorInput,
  Input,
  Notification,
  SelectableAccordion,
  IconButton,
  Tooltip,
  TextButton,
  SectionHelper,
  PulseAnimation,
  CardGalleryItem,
  AutoComplete,
  listItemSelectBuilder,
  CustomModalLayout,
  Modal,
  InputArea,
  ImageViewer,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import React, { useEffect, useRef } from "react";
// import HeroImage from "./whatsapp.png";
import { ReactTagManager } from "react-gtm-ts";
import * as Icons from "@wix/wix-ui-icons-common";
import { dashboard } from "@wix/dashboard";
import { createClient } from "@wix/sdk";

const client = createClient({
  host: dashboard.host(),
  auth: dashboard.auth(),
  modules: {
    dashboard,
  },
});

const countryCodes = require("country-codes-list");
const { phone } = require("phone");

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

const APPS: any = {
  "463fa61d-e677-4847-8749-b708786d1176": {
    icon: "https://static.wixstatic.com/media/bec40d_03c01e5c2064441c81e3135cbd980662~mv2.png",
    page: {
      name: "WhatsApp",
    },
    server: {
      name: "whatsapp",
    },
    default: {
      /* Button Settings */
      button: {
        position:
          "right" /* left, right or false. "position:false" does not pin to the left or right */,
        style: 1 /* Button style. Number between 1 and 7 */,
        src: '<i class="fab fa-whatsapp"></i>' /* Image, Icon or SVG */,
        backgroundColor: "#10c379" /* Html color code */,
        effect: 1 /* Button effect. Number between 1 and 7 */,
        notificationNumber:
          "1" /* Custom text or false. To remove, (notificationNumber:false) */,
        speechBubble:
          "How can we help you?" /* To remove, (speechBubble:false) */,
        pulseEffect: true /* To remove, (pulseEffect:false) */,
        text: {
          /* For Button style larger than 1 */
          title: "Need help? Chat with us" /* Writing is required */,
          description: "Customer Support" /* To remove, (description:false) */,
          online: "I'm Online" /* To remove, (online:false) */,
          offline: "I will be back soon" /* To remove, (offline:false) */,
        },
      },

      /* Popup Settings */
      popup: {
        automaticOpen:
          false /* true or false (Open popup automatically when the page is loaded) */,
        outsideClickClosePopup:
          true /* true or false (Clicking anywhere on the page will close the popup) */,
        effect: 1 /* Popup opening effect. Number between 1 and 15 */,
        header: {
          backgroundColor: "#10c379" /* Html color code */,
          title: "Need help? Chat with us" /* Writing is required */,
          description:
            "Click one of our representatives below" /* To remove, (description:false) */,
        },

        /* Representative Settings */
        persons: [
          /* Copy for more representatives [::Start Copy::] */
          {
            avatar: {
              src: '<img src="https://static.wixstatic.com/media/bec40d_60ca3940b2254633b42e1146f43924f5~mv2.png" alt="">' /* Image, Icon or SVG */,
              backgroundColor: "#ffffff" /* Html color code */,
              onlineCircle:
                true /* Avatar online circle. To remove, (onlineCircle:false) */,
            },
            text: {
              title: "Lorna Hensley (sample)" /* Writing is required */,
              description: "Sales Support" /* To remove, (description:false) */,
              online: "I'm Online" /* To remove, (online:false) */,
              offline: "I will be back soon" /* To remove, (offline:false) */,
            },
            link: {
              desktop:
                "https://web.whatsapp.com/send?phone=13475086396&text=Hi" /* Writing is required */,
              mobile:
                "https://wa.me/13475086396/?text=Hi" /* If it is hidden desktop link will be valid. To remove, (mobile:false) */,
            },
            onlineDay: {
              /* Change the day you are offline like this. (sunday:false) */
              sunday: "00:00-23:59",
              monday: "00:00-23:59",
              tuesday: "00:00-23:59",
              wednesday: "00:00-23:59",
              thursday: "00:00-23:59",
              friday: "00:00-23:59",
              saturday: "00:00-23:59",
            },
          },
          /* [::End Copy::] */

          /* Copy for more representatives [::Start Copy::] */
          {
            avatar: {
              src: '<img src="https://static.wixstatic.com/media/bec40d_60ca3940b2254633b42e1146f43924f5~mv2.png" alt="">' /* Font, Image or SVG */,
              backgroundColor: "#ffffff" /* Html color code */,
              onlineCircle:
                true /* Avatar online circle. To remove, (onlineCircle:false) */,
            },
            text: {
              title: "Mattie Simmonds (sample)" /* Writing is required */,
              description:
                "Customer Support" /* Custom text or false. To remove, (description:false) */,
              online:
                "I'm Online" /* Custom text or false. To remove, (online:false) */,
              offline:
                "I will be back soon" /* Custom text or false. To remove, (offline:false) */,
            },
            link: {
              desktop:
                "https://web.whatsapp.com/send?phone=13475086396&text=Hi" /* Writing is required */,
              mobile:
                "https://wa.me/13475086396/?text=Hi" /* If it is hidden desktop link will be valid. To remove, (mobile:false) */,
            },
            onlineDay: {
              /* Change the day you are offline like this. (sunday:false) */
              sunday: "00:00-23:59",
              monday: "00:00-23:59",
              tuesday: "00:00-23:59",
              wednesday: "00:00-23:59",
              thursday: "00:00-23:59",
              friday: "00:00-23:59",
              saturday: "00:00-23:59",
            },
          },
          /* [::End Copy::] */

          /* Copy for more representatives [::Start Copy::] */
          {
            avatar: {
              src: '<img src="https://static.wixstatic.com/media/bec40d_60ca3940b2254633b42e1146f43924f5~mv2.png" alt="">' /* Font, Image or SVG */,
              backgroundColor: "#ffffff" /* Html color code */,
              onlineCircle:
                true /* Avatar online circle. To remove, (onlineCircle:false) */,
            },
            text: {
              title: "Kole Cleg (sample)" /* Writing is required */,
              description:
                "Techincal Support" /* Custom text or false. To remove, (description:false) */,
              online:
                "I'm Online" /* Custom text or false. To remove, (online:false) */,
              offline:
                "I will be back soon" /* Custom text or false. To remove, (offline:false) */,
            },
            link: {
              desktop:
                "https://web.whatsapp.com/send?phone=13475086396&text=Hi" /* Writing is required */,
              mobile:
                "https://wa.me/13475086396/?text=Hi" /* If it is hidden desktop link will be valid. To remove, (mobile:false) */,
            },
            onlineDay: {
              /* Change the day you are offline like this. (sunday:false) */
              sunday: false,
              monday: false,
              tuesday: false,
              wednesday: false,
              thursday: false,
              friday: false,
              saturday: false,
            },
          },
          /* [::End Copy::] */
        ],
      },

      /* Other Settings */
      sound:
        true /* true (default sound), false or custom sound. Custom sound example, (sound:'assets/sound/notification.mp3') */,
      changeBrowserTitle:
        "New Message!" /* Custom text or false. To remove, (changeBrowserTitle:false) */,
      cookie:
        false /* It does not show the speech bubble, notification number, and pulse effect again for the specified time. For example, do not show for 1 hour, (cookie:1) or to remove, (cookie:false) */,
    },
  },
  "4a40e74c-42a0-4a89-8a00-71eca92ea200": {
    icon: "https://static.wixstatic.com/media/bec40d_1cc72f5a32374a7bb88484b59ecc2a70~mv2.png",
    page: {
      name: "Telegram",
    },
    server: {
      name: "telegram",
    },
    default: {
      /* Button Settings */
      button: {
        position:
          "right" /* left, right or false. "position:false" does not pin to the left or right */,
        style: 1 /* Button style. Number between 1 and 7 */,
        src: '<i class="fab fa-telegram"></i>' /* Image, Icon or SVG */,
        backgroundColor: "#18A3E6" /* Html color code */,
        effect: 1 /* Button effect. Number between 1 and 7 */,
        notificationNumber:
          "1" /* Custom text or false. To remove, (notificationNumber:false) */,
        speechBubble:
          "How can we help you?" /* To remove, (speechBubble:false) */,
        pulseEffect: true /* To remove, (pulseEffect:false) */,
        text: {
          /* For Button style larger than 1 */
          title: "Need help? Chat with us" /* Writing is required */,
          description: "Customer Support" /* To remove, (description:false) */,
          online: "I'm Online" /* To remove, (online:false) */,
          offline: "I will be back soon" /* To remove, (offline:false) */,
        },
      },

      /* Popup Settings */
      popup: {
        automaticOpen:
          false /* true or false (Open popup automatically when the page is loaded) */,
        outsideClickClosePopup:
          true /* true or false (Clicking anywhere on the page will close the popup) */,
        effect: 1 /* Popup opening effect. Number between 1 and 15 */,
        header: {
          backgroundColor: "#18A3E6" /* Html color code */,
          title: "Need help? Chat with us" /* Writing is required */,
          description:
            "Click one of our representatives below" /* To remove, (description:false) */,
        },

        /* Representative Settings */
        persons: [
          /* Copy for more representatives [::Start Copy::] */
          {
            avatar: {
              src: '<img src="https://static.wixstatic.com/media/bec40d_60ca3940b2254633b42e1146f43924f5~mv2.png" alt="">' /* Image, Icon or SVG */,
              backgroundColor: "#ffffff" /* Html color code */,
              onlineCircle:
                true /* Avatar online circle. To remove, (onlineCircle:false) */,
            },
            text: {
              title: "Lorna Hensley (sample)" /* Writing is required */,
              description: "Sales Support" /* To remove, (description:false) */,
              online: "I'm Online" /* To remove, (online:false) */,
              offline: "I will be back soon" /* To remove, (offline:false) */,
            },
            link: {
              desktop: "https://t.me/yourusername" /* Writing is required */,
              mobile:
                "https://t.me/yourusername" /* If it is hidden desktop link will be valid. To remove, (mobile:false) */,
            },
            onlineDay: {
              /* Change the day you are offline like this. (sunday:false) */
              sunday: "00:00-23:59",
              monday: "00:00-23:59",
              tuesday: "00:00-23:59",
              wednesday: "00:00-23:59",
              thursday: "00:00-23:59",
              friday: "00:00-23:59",
              saturday: "00:00-23:59",
            },
          },
          /* [::End Copy::] */

          /* Copy for more representatives [::Start Copy::] */
          {
            avatar: {
              src: '<img src="https://static.wixstatic.com/media/bec40d_60ca3940b2254633b42e1146f43924f5~mv2.png" alt="">' /* Font, Image or SVG */,
              backgroundColor: "#ffffff" /* Html color code */,
              onlineCircle:
                true /* Avatar online circle. To remove, (onlineCircle:false) */,
            },
            text: {
              title: "Mattie Simmonds (sample)" /* Writing is required */,
              description:
                "Customer Support" /* Custom text or false. To remove, (description:false) */,
              online:
                "I'm Online" /* Custom text or false. To remove, (online:false) */,
              offline:
                "I will be back soon" /* Custom text or false. To remove, (offline:false) */,
            },
            link: {
              desktop: "https://t.me/yourusername" /* Writing is required */,
              mobile:
                "https://t.me/yourusername" /* If it is hidden desktop link will be valid. To remove, (mobile:false) */,
            },
            onlineDay: {
              /* Change the day you are offline like this. (sunday:false) */
              sunday: "00:00-23:59",
              monday: "00:00-23:59",
              tuesday: "00:00-23:59",
              wednesday: "00:00-23:59",
              thursday: "00:00-23:59",
              friday: "00:00-23:59",
              saturday: "00:00-23:59",
            },
          },
          /* [::End Copy::] */

          /* Copy for more representatives [::Start Copy::] */
          {
            avatar: {
              src: '<img src="https://static.wixstatic.com/media/bec40d_60ca3940b2254633b42e1146f43924f5~mv2.png" alt="">' /* Font, Image or SVG */,
              backgroundColor: "#ffffff" /* Html color code */,
              onlineCircle:
                true /* Avatar online circle. To remove, (onlineCircle:false) */,
            },
            text: {
              title: "Kole Cleg (sample)" /* Writing is required */,
              description:
                "Techincal Support" /* Custom text or false. To remove, (description:false) */,
              online:
                "I'm Online" /* Custom text or false. To remove, (online:false) */,
              offline:
                "I will be back soon" /* Custom text or false. To remove, (offline:false) */,
            },
            link: {
              desktop: "https://t.me/yourusername" /* Writing is required */,
              mobile:
                "https://t.me/yourusername" /* If it is hidden desktop link will be valid. To remove, (mobile:false) */,
            },
            onlineDay: {
              /* Change the day you are offline like this. (sunday:false) */
              sunday: false,
              monday: false,
              tuesday: false,
              wednesday: false,
              thursday: false,
              friday: false,
              saturday: false,
            },
          },
          /* [::End Copy::] */
        ],
      },

      /* Other Settings */
      sound:
        true /* true (default sound), false or custom sound. Custom sound example, (sound:'assets/sound/notification.mp3') */,
      changeBrowserTitle:
        "New Message!" /* Custom text or false. To remove, (changeBrowserTitle:false) */,
      cookie:
        false /* It does not show the speech bubble, notification number, and pulse effect again for the specified time. For example, do not show for 1 hour, (cookie:1) or to remove, (cookie:false) */,
    },
  },
  "c4aed3c4-7d9c-4a20-90a4-61515043f9f8": {
    icon: "https://static.wixstatic.com/media/bec40d_b6908369f1344d348d142f3ab85f4792~mv2.png",
    page: {
      name: "Messenger",
    },
    server: {
      name: "messenger",
    },
    default: {
      /* Button Settings */
      button: {
        position:
          "right" /* left, right or false. "position:false" does not pin to the left or right */,
        style: 1 /* Button style. Number between 1 and 7 */,
        src: '<i class="fab fa-facebook-messenger"></i>' /* Image, Icon or SVG */,
        backgroundColor: "#0084ff" /* Html color code */,
        effect: 1 /* Button effect. Number between 1 and 7 */,
        notificationNumber:
          "1" /* Custom text or false. To remove, (notificationNumber:false) */,
        speechBubble:
          "How can we help you?" /* To remove, (speechBubble:false) */,
        pulseEffect: true /* To remove, (pulseEffect:false) */,
        text: {
          /* For Button style larger than 1 */
          title: "Need help? Chat with us" /* Writing is required */,
          description: "Customer Support" /* To remove, (description:false) */,
          online: "I'm Online" /* To remove, (online:false) */,
          offline: "I will be back soon" /* To remove, (offline:false) */,
        },
      },

      /* Popup Settings */
      popup: {
        automaticOpen:
          false /* true or false (Open popup automatically when the page is loaded) */,
        outsideClickClosePopup:
          true /* true or false (Clicking anywhere on the page will close the popup) */,
        effect: 1 /* Popup opening effect. Number between 1 and 15 */,
        header: {
          backgroundColor: "#0084ff" /* Html color code */,
          title: "Need help? Chat with us" /* Writing is required */,
          description:
            "Click one of our representatives below" /* To remove, (description:false) */,
        },

        /* Representative Settings */
        persons: [
          /* Copy for more representatives [::Start Copy::] */
          {
            avatar: {
              src: '<img src="https://static.wixstatic.com/media/bec40d_60ca3940b2254633b42e1146f43924f5~mv2.png" alt="">' /* Image, Icon or SVG */,
              backgroundColor: "#ffffff" /* Html color code */,
              onlineCircle:
                true /* Avatar online circle. To remove, (onlineCircle:false) */,
            },
            text: {
              title: "Lorna Hensley (sample)" /* Writing is required */,
              description: "Sales Support" /* To remove, (description:false) */,
              online: "I'm Online" /* To remove, (online:false) */,
              offline: "I will be back soon" /* To remove, (offline:false) */,
            },
            link: {
              desktop: "http://m.me/PAGE-NAME" /* Writing is required */,
              mobile:
                "http://m.me/PAGE-NAME" /* If it is hidden desktop link will be valid. To remove, (mobile:false) */,
            },
            onlineDay: {
              /* Change the day you are offline like this. (sunday:false) */
              sunday: "00:00-23:59",
              monday: "00:00-23:59",
              tuesday: "00:00-23:59",
              wednesday: "00:00-23:59",
              thursday: "00:00-23:59",
              friday: "00:00-23:59",
              saturday: "00:00-23:59",
            },
          },
          /* [::End Copy::] */

          /* Copy for more representatives [::Start Copy::] */
          {
            avatar: {
              src: '<img src="https://static.wixstatic.com/media/bec40d_60ca3940b2254633b42e1146f43924f5~mv2.png" alt="">' /* Font, Image or SVG */,
              backgroundColor: "#ffffff" /* Html color code */,
              onlineCircle:
                true /* Avatar online circle. To remove, (onlineCircle:false) */,
            },
            text: {
              title: "Mattie Simmonds (sample)" /* Writing is required */,
              description:
                "Customer Support" /* Custom text or false. To remove, (description:false) */,
              online:
                "I'm Online" /* Custom text or false. To remove, (online:false) */,
              offline:
                "I will be back soon" /* Custom text or false. To remove, (offline:false) */,
            },
            link: {
              desktop: "http://m.me/PAGE-NAME" /* Writing is required */,
              mobile:
                "http://m.me/PAGE-NAME" /* If it is hidden desktop link will be valid. To remove, (mobile:false) */,
            },
            onlineDay: {
              /* Change the day you are offline like this. (sunday:false) */
              sunday: "00:00-23:59",
              monday: "00:00-23:59",
              tuesday: "00:00-23:59",
              wednesday: "00:00-23:59",
              thursday: "00:00-23:59",
              friday: "00:00-23:59",
              saturday: "00:00-23:59",
            },
          },
          /* [::End Copy::] */

          /* Copy for more representatives [::Start Copy::] */
          {
            avatar: {
              src: '<img src="https://static.wixstatic.com/media/bec40d_60ca3940b2254633b42e1146f43924f5~mv2.png" alt="">' /* Font, Image or SVG */,
              backgroundColor: "#ffffff" /* Html color code */,
              onlineCircle:
                true /* Avatar online circle. To remove, (onlineCircle:false) */,
            },
            text: {
              title: "Kole Cleg (sample)" /* Writing is required */,
              description:
                "Techincal Support" /* Custom text or false. To remove, (description:false) */,
              online:
                "I'm Online" /* Custom text or false. To remove, (online:false) */,
              offline:
                "I will be back soon" /* Custom text or false. To remove, (offline:false) */,
            },
            link: {
              desktop: "http://m.me/PAGE-NAME" /* Writing is required */,
              mobile:
                "http://m.me/PAGE-NAME" /* If it is hidden desktop link will be valid. To remove, (mobile:false) */,
            },
            onlineDay: {
              /* Change the day you are offline like this. (sunday:false) */
              sunday: false,
              monday: false,
              tuesday: false,
              wednesday: false,
              thursday: false,
              friday: false,
              saturday: false,
            },
          },
          /* [::End Copy::] */
        ],
      },

      /* Other Settings */
      sound:
        true /* true (default sound), false or custom sound. Custom sound example, (sound:'assets/sound/notification.mp3') */,
      changeBrowserTitle:
        "New Message!" /* Custom text or false. To remove, (changeBrowserTitle:false) */,
      cookie:
        false /* It does not show the speech bubble, notification number, and pulse effect again for the specified time. For example, do not show for 1 hour, (cookie:1) or to remove, (cookie:false) */,
    },
  },
  "6986524e-cf47-4bf4-b01e-028db9023c82": {
    icon: "https://static.wixstatic.com/media/bec40d_be572fe3026d45d98e1538bca6ceeb32~mv2.png",
    page: {
      name: "Instagram",
    },
    server: {
      name: "instagram",
    },
    default: {
      /* Button Settings */
      button: {
        position:
          "right" /* left, right or false. "position:false" does not pin to the left or right */,
        style: 1 /* Button style. Number between 1 and 7 */,
        src: '<i class="fab fa-instagram"></i>' /* Image, Icon or SVG */,
        backgroundColor: "#C837AB" /* Html color code */,
        effect: 1 /* Button effect. Number between 1 and 7 */,
        notificationNumber:
          "1" /* Custom text or false. To remove, (notificationNumber:false) */,
        speechBubble:
          "How can we help you?" /* To remove, (speechBubble:false) */,
        pulseEffect: true /* To remove, (pulseEffect:false) */,
        text: {
          /* For Button style larger than 1 */
          title: "Need help? Chat with us" /* Writing is required */,
          description: "Customer Support" /* To remove, (description:false) */,
          online: "I'm Online" /* To remove, (online:false) */,
          offline: "I will be back soon" /* To remove, (offline:false) */,
        },
      },

      /* Popup Settings */
      popup: {
        automaticOpen:
          false /* true or false (Open popup automatically when the page is loaded) */,
        outsideClickClosePopup:
          true /* true or false (Clicking anywhere on the page will close the popup) */,
        effect: 1 /* Popup opening effect. Number between 1 and 15 */,
        header: {
          backgroundColor: "#C837AB" /* Html color code */,
          title: "Need help? Chat with us" /* Writing is required */,
          description:
            "Click one of our representatives below" /* To remove, (description:false) */,
        },

        /* Representative Settings */
        persons: [
          /* Copy for more representatives [::Start Copy::] */
          {
            avatar: {
              src: '<img src="https://static.wixstatic.com/media/bec40d_60ca3940b2254633b42e1146f43924f5~mv2.png" alt="">' /* Image, Icon or SVG */,
              backgroundColor: "#ffffff" /* Html color code */,
              onlineCircle:
                true /* Avatar online circle. To remove, (onlineCircle:false) */,
            },
            text: {
              title: "Lorna Hensley (sample)" /* Writing is required */,
              description: "Sales Support" /* To remove, (description:false) */,
              online: "I'm Online" /* To remove, (online:false) */,
              offline: "I will be back soon" /* To remove, (offline:false) */,
            },
            link: {
              desktop: "https://ig.me/m/wix" /* Writing is required */,
              mobile:
                "https://ig.me/m/wix" /* If it is hidden desktop link will be valid. To remove, (mobile:false) */,
            },
            onlineDay: {
              /* Change the day you are offline like this. (sunday:false) */
              sunday: "00:00-23:59",
              monday: "00:00-23:59",
              tuesday: "00:00-23:59",
              wednesday: "00:00-23:59",
              thursday: "00:00-23:59",
              friday: "00:00-23:59",
              saturday: "00:00-23:59",
            },
          },
          /* [::End Copy::] */

          /* Copy for more representatives [::Start Copy::] */
          {
            avatar: {
              src: '<img src="https://static.wixstatic.com/media/bec40d_60ca3940b2254633b42e1146f43924f5~mv2.png" alt="">' /* Font, Image or SVG */,
              backgroundColor: "#ffffff" /* Html color code */,
              onlineCircle:
                true /* Avatar online circle. To remove, (onlineCircle:false) */,
            },
            text: {
              title: "Mattie Simmonds (sample)" /* Writing is required */,
              description:
                "Customer Support" /* Custom text or false. To remove, (description:false) */,
              online:
                "I'm Online" /* Custom text or false. To remove, (online:false) */,
              offline:
                "I will be back soon" /* Custom text or false. To remove, (offline:false) */,
            },
            link: {
              desktop: "https://ig.me/m/wix" /* Writing is required */,
              mobile:
                "https://ig.me/m/wix" /* If it is hidden desktop link will be valid. To remove, (mobile:false) */,
            },
            onlineDay: {
              /* Change the day you are offline like this. (sunday:false) */
              sunday: "00:00-23:59",
              monday: "00:00-23:59",
              tuesday: "00:00-23:59",
              wednesday: "00:00-23:59",
              thursday: "00:00-23:59",
              friday: "00:00-23:59",
              saturday: "00:00-23:59",
            },
          },
          /* [::End Copy::] */

          /* Copy for more representatives [::Start Copy::] */
          {
            avatar: {
              src: '<img src="https://static.wixstatic.com/media/bec40d_60ca3940b2254633b42e1146f43924f5~mv2.png" alt="">' /* Font, Image or SVG */,
              backgroundColor: "#ffffff" /* Html color code */,
              onlineCircle:
                true /* Avatar online circle. To remove, (onlineCircle:false) */,
            },
            text: {
              title: "Kole Cleg (sample)" /* Writing is required */,
              description:
                "Techincal Support" /* Custom text or false. To remove, (description:false) */,
              online:
                "I'm Online" /* Custom text or false. To remove, (online:false) */,
              offline:
                "I will be back soon" /* Custom text or false. To remove, (offline:false) */,
            },
            link: {
              desktop: "https://ig.me/m/wix" /* Writing is required */,
              mobile:
                "https://ig.me/m/wix" /* If it is hidden desktop link will be valid. To remove, (mobile:false) */,
            },
            onlineDay: {
              /* Change the day you are offline like this. (sunday:false) */
              sunday: false,
              monday: false,
              tuesday: false,
              wednesday: false,
              thursday: false,
              friday: false,
              saturday: false,
            },
          },
          /* [::End Copy::] */
        ],
      },

      /* Other Settings */
      sound:
        true /* true (default sound), false or custom sound. Custom sound example, (sound:'assets/sound/notification.mp3') */,
      changeBrowserTitle:
        "New Message!" /* Custom text or false. To remove, (changeBrowserTitle:false) */,
      cookie:
        false /* It does not show the speech bubble, notification number, and pulse effect again for the specified time. For example, do not show for 1 hour, (cookie:1) or to remove, (cookie:false) */,
    },
  },
  "226c485b-7f5d-41a8-b75c-ac33b079e821": {
    icon: "https://static.wixstatic.com/media/bec40d_c7693c68ee574e8d8c77e5e1d5ca657e~mv2.png",
    page: {
      name: "Facebook",
    },
    server: {
      name: "facebook",
    },
    default: {
      /* Button Settings */
      button: {
        position:
          "right" /* left, right or false. "position:false" does not pin to the left or right */,
        style: 1 /* Button style. Number between 1 and 7 */,
        src: '<i class="fab fa-facebook"></i>' /* Image, Icon or SVG */,
        backgroundColor: "#0062E0" /* Html color code */,
        effect: 1 /* Button effect. Number between 1 and 7 */,
        notificationNumber:
          "1" /* Custom text or false. To remove, (notificationNumber:false) */,
        speechBubble:
          "How can we help you?" /* To remove, (speechBubble:false) */,
        pulseEffect: true /* To remove, (pulseEffect:false) */,
        text: {
          /* For Button style larger than 1 */
          title: "Need help? Chat with us" /* Writing is required */,
          description: "Customer Support" /* To remove, (description:false) */,
          online: "I'm Online" /* To remove, (online:false) */,
          offline: "I will be back soon" /* To remove, (offline:false) */,
        },
      },

      /* Popup Settings */
      popup: {
        automaticOpen:
          false /* true or false (Open popup automatically when the page is loaded) */,
        outsideClickClosePopup:
          true /* true or false (Clicking anywhere on the page will close the popup) */,
        effect: 1 /* Popup opening effect. Number between 1 and 15 */,
        header: {
          backgroundColor: "#0062E0" /* Html color code */,
          title: "Need help? Chat with us" /* Writing is required */,
          description:
            "Click one of our representatives below" /* To remove, (description:false) */,
        },

        /* Representative Settings */
        persons: [
          /* Copy for more representatives [::Start Copy::] */
          {
            avatar: {
              src: '<img src="https://static.wixstatic.com/media/bec40d_60ca3940b2254633b42e1146f43924f5~mv2.png" alt="">' /* Image, Icon or SVG */,
              backgroundColor: "#ffffff" /* Html color code */,
              onlineCircle:
                true /* Avatar online circle. To remove, (onlineCircle:false) */,
            },
            text: {
              title: "Lorna Hensley (sample)" /* Writing is required */,
              description: "Sales Support" /* To remove, (description:false) */,
              online: "I'm Online" /* To remove, (online:false) */,
              offline: "I will be back soon" /* To remove, (offline:false) */,
            },
            link: {
              desktop:
                "https://www.facebook.com/wix/" /* Writing is required */,
              mobile:
                "https://www.facebook.com/wix/" /* If it is hidden desktop link will be valid. To remove, (mobile:false) */,
            },
            onlineDay: {
              /* Change the day you are offline like this. (sunday:false) */
              sunday: "00:00-23:59",
              monday: "00:00-23:59",
              tuesday: "00:00-23:59",
              wednesday: "00:00-23:59",
              thursday: "00:00-23:59",
              friday: "00:00-23:59",
              saturday: "00:00-23:59",
            },
          },
          /* [::End Copy::] */

          /* Copy for more representatives [::Start Copy::] */
          {
            avatar: {
              src: '<img src="https://static.wixstatic.com/media/bec40d_60ca3940b2254633b42e1146f43924f5~mv2.png" alt="">' /* Font, Image or SVG */,
              backgroundColor: "#ffffff" /* Html color code */,
              onlineCircle:
                true /* Avatar online circle. To remove, (onlineCircle:false) */,
            },
            text: {
              title: "Mattie Simmonds (sample)" /* Writing is required */,
              description:
                "Customer Support" /* Custom text or false. To remove, (description:false) */,
              online:
                "I'm Online" /* Custom text or false. To remove, (online:false) */,
              offline:
                "I will be back soon" /* Custom text or false. To remove, (offline:false) */,
            },
            link: {
              desktop:
                "https://www.facebook.com/wix/" /* Writing is required */,
              mobile:
                "https://www.facebook.com/wix/" /* If it is hidden desktop link will be valid. To remove, (mobile:false) */,
            },
            onlineDay: {
              /* Change the day you are offline like this. (sunday:false) */
              sunday: "00:00-23:59",
              monday: "00:00-23:59",
              tuesday: "00:00-23:59",
              wednesday: "00:00-23:59",
              thursday: "00:00-23:59",
              friday: "00:00-23:59",
              saturday: "00:00-23:59",
            },
          },
          /* [::End Copy::] */

          /* Copy for more representatives [::Start Copy::] */
          {
            avatar: {
              src: '<img src="https://static.wixstatic.com/media/bec40d_60ca3940b2254633b42e1146f43924f5~mv2.png" alt="">' /* Font, Image or SVG */,
              backgroundColor: "#ffffff" /* Html color code */,
              onlineCircle:
                true /* Avatar online circle. To remove, (onlineCircle:false) */,
            },
            text: {
              title: "Kole Cleg (sample)" /* Writing is required */,
              description:
                "Techincal Support" /* Custom text or false. To remove, (description:false) */,
              online:
                "I'm Online" /* Custom text or false. To remove, (online:false) */,
              offline:
                "I will be back soon" /* Custom text or false. To remove, (offline:false) */,
            },
            link: {
              desktop:
                "https://www.facebook.com/wix/" /* Writing is required */,
              mobile:
                "https://www.facebook.com/wix/" /* If it is hidden desktop link will be valid. To remove, (mobile:false) */,
            },
            onlineDay: {
              /* Change the day you are offline like this. (sunday:false) */
              sunday: false,
              monday: false,
              tuesday: false,
              wednesday: false,
              thursday: false,
              friday: false,
              saturday: false,
            },
          },
          /* [::End Copy::] */
        ],
      },

      /* Other Settings */
      sound:
        true /* true (default sound), false or custom sound. Custom sound example, (sound:'assets/sound/notification.mp3') */,
      changeBrowserTitle:
        "New Message!" /* Custom text or false. To remove, (changeBrowserTitle:false) */,
      cookie:
        false /* It does not show the speech bubble, notification number, and pulse effect again for the specified time. For example, do not show for 1 hour, (cookie:1) or to remove, (cookie:false) */,
    },
  },
  "938f2e51-0e4e-4ba2-a876-6cfb15a74828": {
    icon: "https://static.wixstatic.com/media/bec40d_3c526bc087f6466d88a4163e6bc32582~mv2.png",
    page: {
      name: "TikTok",
    },
    server: {
      name: "tiktok",
    },
    default: {
      /* Button Settings */
      button: {
        position:
          "right" /* left, right or false. "position:false" does not pin to the left or right */,
        style: 1 /* Button style. Number between 1 and 7 */,
        src: '<i class="fab fa-tiktok"></i>' /* Image, Icon or SVG */,
        backgroundColor: "#FF004F" /* Html color code */,
        effect: 1 /* Button effect. Number between 1 and 7 */,
        notificationNumber:
          "1" /* Custom text or false. To remove, (notificationNumber:false) */,
        speechBubble:
          "How can we help you?" /* To remove, (speechBubble:false) */,
        pulseEffect: true /* To remove, (pulseEffect:false) */,
        text: {
          /* For Button style larger than 1 */
          title: "Need help? Chat with us" /* Writing is required */,
          description: "Customer Support" /* To remove, (description:false) */,
          online: "I'm Online" /* To remove, (online:false) */,
          offline: "I will be back soon" /* To remove, (offline:false) */,
        },
      },

      /* Popup Settings */
      popup: {
        automaticOpen:
          false /* true or false (Open popup automatically when the page is loaded) */,
        outsideClickClosePopup:
          true /* true or false (Clicking anywhere on the page will close the popup) */,
        effect: 1 /* Popup opening effect. Number between 1 and 15 */,
        header: {
          backgroundColor: "#FF004F" /* Html color code */,
          title: "Need help? Chat with us" /* Writing is required */,
          description:
            "Click one of our representatives below" /* To remove, (description:false) */,
        },

        /* Representative Settings */
        persons: [
          /* Copy for more representatives [::Start Copy::] */
          {
            avatar: {
              src: '<img src="https://static.wixstatic.com/media/bec40d_60ca3940b2254633b42e1146f43924f5~mv2.png" alt="">' /* Image, Icon or SVG */,
              backgroundColor: "#ffffff" /* Html color code */,
              onlineCircle:
                true /* Avatar online circle. To remove, (onlineCircle:false) */,
            },
            text: {
              title: "Lorna Hensley (sample)" /* Writing is required */,
              description: "Sales Support" /* To remove, (description:false) */,
              online: "I'm Online" /* To remove, (online:false) */,
              offline: "I will be back soon" /* To remove, (offline:false) */,
            },
            link: {
              desktop: "https://www.tiktok.com/@wix" /* Writing is required */,
              mobile:
                "https://www.tiktok.com/@wix" /* If it is hidden desktop link will be valid. To remove, (mobile:false) */,
            },
            onlineDay: {
              /* Change the day you are offline like this. (sunday:false) */
              sunday: "00:00-23:59",
              monday: "00:00-23:59",
              tuesday: "00:00-23:59",
              wednesday: "00:00-23:59",
              thursday: "00:00-23:59",
              friday: "00:00-23:59",
              saturday: "00:00-23:59",
            },
          },
          /* [::End Copy::] */

          /* Copy for more representatives [::Start Copy::] */
          {
            avatar: {
              src: '<img src="https://static.wixstatic.com/media/bec40d_60ca3940b2254633b42e1146f43924f5~mv2.png" alt="">' /* Font, Image or SVG */,
              backgroundColor: "#ffffff" /* Html color code */,
              onlineCircle:
                true /* Avatar online circle. To remove, (onlineCircle:false) */,
            },
            text: {
              title: "Mattie Simmonds (sample)" /* Writing is required */,
              description:
                "Customer Support" /* Custom text or false. To remove, (description:false) */,
              online:
                "I'm Online" /* Custom text or false. To remove, (online:false) */,
              offline:
                "I will be back soon" /* Custom text or false. To remove, (offline:false) */,
            },
            link: {
              desktop: "https://www.tiktok.com/@wix" /* Writing is required */,
              mobile:
                "https://www.tiktok.com/@wix" /* If it is hidden desktop link will be valid. To remove, (mobile:false) */,
            },
            onlineDay: {
              /* Change the day you are offline like this. (sunday:false) */
              sunday: "00:00-23:59",
              monday: "00:00-23:59",
              tuesday: "00:00-23:59",
              wednesday: "00:00-23:59",
              thursday: "00:00-23:59",
              friday: "00:00-23:59",
              saturday: "00:00-23:59",
            },
          },
          /* [::End Copy::] */

          /* Copy for more representatives [::Start Copy::] */
          {
            avatar: {
              src: '<img src="https://static.wixstatic.com/media/bec40d_60ca3940b2254633b42e1146f43924f5~mv2.png" alt="">' /* Font, Image or SVG */,
              backgroundColor: "#ffffff" /* Html color code */,
              onlineCircle:
                true /* Avatar online circle. To remove, (onlineCircle:false) */,
            },
            text: {
              title: "Kole Cleg (sample)" /* Writing is required */,
              description:
                "Techincal Support" /* Custom text or false. To remove, (description:false) */,
              online:
                "I'm Online" /* Custom text or false. To remove, (online:false) */,
              offline:
                "I will be back soon" /* Custom text or false. To remove, (offline:false) */,
            },
            link: {
              desktop: "https://www.tiktok.com/@wix" /* Writing is required */,
              mobile:
                "https://www.tiktok.com/@wix" /* If it is hidden desktop link will be valid. To remove, (mobile:false) */,
            },
            onlineDay: {
              /* Change the day you are offline like this. (sunday:false) */
              sunday: false,
              monday: false,
              tuesday: false,
              wednesday: false,
              thursday: false,
              friday: false,
              saturday: false,
            },
          },
          /* [::End Copy::] */
        ],
      },

      /* Other Settings */
      sound:
        true /* true (default sound), false or custom sound. Custom sound example, (sound:'assets/sound/notification.mp3') */,
      changeBrowserTitle:
        "New Message!" /* Custom text or false. To remove, (changeBrowserTitle:false) */,
      cookie:
        false /* It does not show the speech bubble, notification number, and pulse effect again for the specified time. For example, do not show for 1 hour, (cookie:1) or to remove, (cookie:false) */,
    },
  },
};

const SAMPLE_PERSON: any =
  /* Copy for more representatives [::Start Copy::] */
  {
    avatar: {
      src: '<img src="https://static.wixstatic.com/media/bec40d_60ca3940b2254633b42e1146f43924f5~mv2.png" alt="">' /* Image, Icon or SVG */,
      backgroundColor: "#ffffff" /* Html color code */,
      onlineCircle:
        true /* Avatar online circle. To remove, (onlineCircle:false) */,
    },
    text: {
      title: "Lorna Hensley (sample)" /* Writing is required */,
      description: "Sales Support" /* To remove, (description:false) */,
      online: "I'm Online" /* To remove, (online:false) */,
      offline: "I will be back soon" /* To remove, (offline:false) */,
    },
    link: {
      desktop:
        "https://web.whatsapp.com/send?phone=13475086396&text=Hi" /* Writing is required */,
      mobile:
        "https://wa.me/13475086396/?text=Hi" /* If it is hidden desktop link will be valid. To remove, (mobile:false) */,
    },
    onlineDay: {
      /* Change the day you are offline like this. (sunday:false) */
      sunday: "00:00-23:59",
      monday: "00:00-23:59",
      tuesday: "00:00-23:59",
      wednesday: "00:00-23:59",
      thursday: "00:00-23:59",
      friday: "00:00-23:59",
      saturday: "00:00-23:59",
    },
  };

function App() {
  ReactTagManager.init(tagManagerArgs);
  const personList = useRef(null);
  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");
  const [isEnabled, setIsEnabled] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [trialRemainingDate, setTrialRemainingDate] = React.useState(0);
  const [isShowSaved, setIsShowSaved] = React.useState(false);
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [canUsePremium, setCanUsePremium] = React.useState(false);
  const [appId, setAppId] = React.useState(
    "463fa61d-e677-4847-8749-b708786d1176"
  );
  const [isShowWaHelper, setIsShowWaHelper] = React.useState(false);
  const [selectedPersonIndex, setSelectedPersonIndex] = React.useState(0);

  const [showPulseOnLink, setShowPulseOnLink] = React.useState(false);

  const [config, setConfig] = React.useState({
    /* Button Settings */
    button: {
      position:
        "right" /* left, right or false. "position:false" does not pin to the left or right */,
      style: 1 /* Button style. Number between 1 and 7 */,
      src: '<i class="fab fa-whatsapp"></i>' /* Image, Icon or SVG */,
      backgroundColor: "#10c379" /* Html color code */,
      effect: 1 /* Button effect. Number between 1 and 7 */,
      notificationNumber:
        "1" /* Custom text or false. To remove, (notificationNumber:false) */,
      speechBubble:
        "How can we help you?" /* To remove, (speechBubble:false) */,
      pulseEffect: true /* To remove, (pulseEffect:false) */,
      text: {
        /* For Button style larger than 1 */
        title: "Need help? Chat with us" /* Writing is required */,
        description: "Customer Support" /* To remove, (description:false) */,
        online: "I'm Online" /* To remove, (online:false) */,
        offline: "I will be back soon" /* To remove, (offline:false) */,
      },
    },

    /* Popup Settings */
    popup: {
      automaticOpen:
        false /* true or false (Open popup automatically when the page is loaded) */,
      outsideClickClosePopup:
        true /* true or false (Clicking anywhere on the page will close the popup) */,
      effect: 1 /* Popup opening effect. Number between 1 and 15 */,
      header: {
        backgroundColor: "#10c379" /* Html color code */,
        title: "Need help? Chat with us" /* Writing is required */,
        description:
          "Click one of our representatives below" /* To remove, (description:false) */,
      },

      /* Representative Settings */
      persons: [
        /* Copy for more representatives [::Start Copy::] */
        {
          avatar: {
            src: '<img src="https://static.wixstatic.com/media/bec40d_60ca3940b2254633b42e1146f43924f5~mv2.png" alt="">' /* Image, Icon or SVG */,
            backgroundColor: "#ffffff" /* Html color code */,
            onlineCircle:
              true /* Avatar online circle. To remove, (onlineCircle:false) */,
          },
          text: {
            title: "Lorna Hensley (sample)" /* Writing is required */,
            description: "Sales Support" /* To remove, (description:false) */,
            online: "I'm Online" /* To remove, (online:false) */,
            offline: "I will be back soon" /* To remove, (offline:false) */,
          },
          link: {
            desktop:
              "https://web.whatsapp.com/send?phone=13475086396&text=Hi" /* Writing is required */,
            mobile:
              "https://wa.me/13475086396/?text=Hi" /* If it is hidden desktop link will be valid. To remove, (mobile:false) */,
          },
          onlineDay: {
            /* Change the day you are offline like this. (sunday:false) */
            sunday: "00:00-23:59",
            monday: "00:00-23:59",
            tuesday: "00:00-23:59",
            wednesday: "00:00-23:59",
            thursday: "00:00-23:59",
            friday: "00:00-23:59",
            saturday: "00:00-23:59",
          },
        },
        /* [::End Copy::] */

        /* Copy for more representatives [::Start Copy::] */
        {
          avatar: {
            src: '<img src="https://static.wixstatic.com/media/bec40d_60ca3940b2254633b42e1146f43924f5~mv2.png" alt="">' /* Font, Image or SVG */,
            backgroundColor: "#ffffff" /* Html color code */,
            onlineCircle:
              true /* Avatar online circle. To remove, (onlineCircle:false) */,
          },
          text: {
            title: "Mattie Simmonds (sample)" /* Writing is required */,
            description:
              "Customer Support" /* Custom text or false. To remove, (description:false) */,
            online:
              "I'm Online" /* Custom text or false. To remove, (online:false) */,
            offline:
              "I will be back soon" /* Custom text or false. To remove, (offline:false) */,
          },
          link: {
            desktop:
              "https://web.whatsapp.com/send?phone=13475086396&text=Hi" /* Writing is required */,
            mobile:
              "https://wa.me/13475086396/?text=Hi" /* If it is hidden desktop link will be valid. To remove, (mobile:false) */,
          },
          onlineDay: {
            /* Change the day you are offline like this. (sunday:false) */
            sunday: "00:00-23:59",
            monday: "00:00-23:59",
            tuesday: "00:00-23:59",
            wednesday: "00:00-23:59",
            thursday: "00:00-23:59",
            friday: "00:00-23:59",
            saturday: "00:00-23:59",
          },
        },
        /* [::End Copy::] */

        /* Copy for more representatives [::Start Copy::] */
        {
          avatar: {
            src: '<img src="https://static.wixstatic.com/media/bec40d_60ca3940b2254633b42e1146f43924f5~mv2.png" alt="">' /* Font, Image or SVG */,
            backgroundColor: "#ffffff" /* Html color code */,
            onlineCircle:
              true /* Avatar online circle. To remove, (onlineCircle:false) */,
          },
          text: {
            title: "Kole Cleg (sample)" /* Writing is required */,
            description:
              "Techincal Support" /* Custom text or false. To remove, (description:false) */,
            online:
              "I'm Online" /* Custom text or false. To remove, (online:false) */,
            offline:
              "I will be back soon" /* Custom text or false. To remove, (offline:false) */,
          },
          link: {
            desktop:
              "https://web.whatsapp.com/send?phone=13475086396&text=Hi" /* Writing is required */,
            mobile:
              "https://wa.me/13475086396/?text=Hi" /* If it is hidden desktop link will be valid. To remove, (mobile:false) */,
          },
          onlineDay: {
            /* Change the day you are offline like this. (sunday:false) */
            sunday: false,
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
          },
        },
        /* [::End Copy::] */
      ],
    },

    /* Other Settings */
    sound:
      true /* true (default sound), false or custom sound. Custom sound example, (sound:'assets/sound/notification.mp3') */,
    changeBrowserTitle:
      "New Message!" /* Custom text or false. To remove, (changeBrowserTitle:false) */,
    cookie:
      false /* It does not show the speech bubble, notification number, and pulse effect again for the specified time. For example, do not show for 1 hour, (cookie:1) or to remove, (cookie:false) */,

    default: true,
  } as any);

  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  });

  useEffect(() => {
    if (isUpgraded) {
      setCanUsePremium(true);
    } else {
      if (trialRemainingDate > 0) {
        setCanUsePremium(true);
      } else {
        setCanUsePremium(false);
      }
    }
  }, [isUpgraded, trialRemainingDate]);

  useEffect(() => {
    // find appId from url
    const appId = new URLSearchParams(window.location.search).get("appId");
    if (appId) {
      setAppId(appId);
    }
  }, []);

  useEffect(() => {
    if (appId) {
      setConfig(APPS[appId]?.default);
      setIntercomIdentity();
      setInstance();
      getSettings();
      getTrial();
    }
  }, [appId]);

  useEffect(() => {
    console.log(config);
    if (config?.button?.style && !token) {
      try {
        // remove existing chat element
        window.$("#whatsapp-preview").remove();
        // create new chat element to body
        window.$("body").append('<div id="whatsapp-preview"></div>');
        window.$("#whatsapp-preview").czmChatSupport(config);
      } catch (error) {
        console.error(error);
      }
    }
  }, [config]);

  const BASE_URL = `https://certifiedcode.wixsite.com/${APPS[appId]?.server.name}/_functions`;

  function setIntercomIdentity() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/intercom", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.hash && data.email) {
          window.Intercom("boot", {
            email: data.email,
            user_hash: data.hash,
          });
        }
      })
      .catch(() => {});
  }

  function setInstance() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/instance", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setInstanceData(data);
        setIsUpgraded(data.instance.isFree === false);
      })
      .catch(() => {});
  }

  function getSettings() {
    fetch(BASE_URL + "/settings", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setIsEnabled(data.isEnabled);
        if (data?.config) {
          setConfig(data.config);
        }
      })
      .catch(() => {});
  }

  function setSettings() {
    setIsShowSaved(false);
    fetch(BASE_URL + "/settings", {
      method: "POST",
      headers: {
        Authorization: instance || "",
      },
      body: JSON.stringify({
        isEnabled: isEnabled,
        config,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setIsEnabled(data.isEnabled);
        console.log("set settings done");
        setIsShowSaved(true);
        setTimeout(() => {
          if (isShowSaved) {
            setIsShowSaved(false);
          }
        }, 3000);
      })
      .catch(() => {});
  }

  function getTrial() {
    fetch(`${BASE_URL}/premium`, {
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setTrialRemainingDate(data?.trial);
      })
      .catch(() => {});
  }

  const scrollToComponent = () => {
    // @ts-ignore
    personList.current.scrollIntoView();
    setShowPulseOnLink(true);
    setTimeout(() => {
      setShowPulseOnLink(false);
    }, 3000);
  };

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=${
      new URLSearchParams(window.location.search).get("appId") ||
      "463fa61d-e677-4847-8749-b708786d1176"
    }&redirectUrl=https://certifiedcode.wixsite.com/${
      APPS[appId]?.server.name
    }/_functions/@certifiedcode/base-backend/auth`;
    return <></>;
  }

  const PremiumHint = () => (
    <Tooltip content="Upgrade to Premium to edit">
      <IconButton
        size="tiny"
        skin="premium"
        as={"a"}
        href={`https://www.wix.com/apps/upgrade/${appId}?appInstanceId=${
          (instanceData as any)?.instance?.instanceId
        }`}
        target="_blank"
      >
        <Icons.PremiumFilledSmall />
      </IconButton>
    </Tooltip>
  );

  const WaHelper = ({ onGenerateLink }: any) => {
    const [number, setNumber] = React.useState("");
    const [countryCode, setCountryCode] = React.useState("+1");
    const [defaultMessage, setDefaultMessage] = React.useState("Hello");

    const list = countryCodes.customList(
      "countryCallingCode",
      "{countryNameEn}"
    );

    const options = Object.keys(
      countryCodes.customList("countryCallingCode", "{countryNameEn}")
    ).map((k: any, i: number) =>
      listItemSelectBuilder({ id: `+${k}`, title: list[k], suffix: `+${k}` })
    );
    const onChange = (e: any) => {
      const { value } = e.target;
      setNumber(value);
    };

    const renderAutoComplete = () => (
      <AutoComplete
        popoverProps={{ placement: "bottom-start" }}
        value={countryCode}
        options={options}
        onSelect={(option: any) => {
          console.log(option);
          setCountryCode(option.id);
        }}
      />
    );

    const generateAndSendLink = () => {
      const fullNumber = countryCode.replace("+", "") + number;
      const encodedMessage = encodeURIComponent(defaultMessage);
      onGenerateLink({
        desktop: `https://web.whatsapp.com/send?phone=${fullNumber}&text=${encodedMessage}`,
        mobile: `https://wa.me/${fullNumber}/?text=${encodedMessage}`,
      });
    };

    return (
      <CustomModalLayout
        primaryButtonText="Apply"
        primaryButtonOnClick={() => {
          generateAndSendLink();
          setIsShowWaHelper(false);
        }}
        secondaryButtonText="Cancel"
        secondaryButtonOnClick={() => setIsShowWaHelper(false)}
        onCloseButtonClick={() => setIsShowWaHelper(false)}
        title="Generate WhatsApp Link"
        overflowY="visible"
        height="350px"
        content={
          <FormField
            label="Phone number"
            status={
              phone(countryCode + number)?.isValid ? undefined : "warning"
            }
            statusMessage={
              phone(countryCode + number)?.isValid
                ? ""
                : "The number doesn't seem to be valid, your link may not work properly."
            }
          >
            <Layout gap={6}>
              <Cell span={2}>{renderAutoComplete()}</Cell>
              <Cell span={10}>
                <Input
                  type="number"
                  placeholder="12345678"
                  onChange={onChange}
                  value={number}
                />
              </Cell>
              <Cell span={12}>
                <FormField label="Default message">
                  <InputArea
                    value={defaultMessage}
                    onChange={(e) => {
                      setDefaultMessage(e.target.value);
                    }}
                  />
                </FormField>
              </Cell>
            </Layout>
          </FormField>
        }
      />
    );
  };

  const handleGenerateLink = (link: any) => {
    setConfig({
      ...config,
      popup: {
        ...config.popup,
        persons: [
          ...config.popup.persons.slice(0, selectedPersonIndex),
          {
            ...config.popup.persons[selectedPersonIndex],
            link,
          },
          ...config.popup.persons.slice(selectedPersonIndex + 1),
        ],
      },
    });
  };

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Notification show={isShowSaved} autoHideTimeout={3000}>
        <Notification.TextLabel>
          Changes have been saved successfully
        </Notification.TextLabel>
        <Notification.CloseButton />
      </Notification>
      <Modal
        isOpen={isShowWaHelper}
        onRequestClose={() => setIsShowWaHelper(false)}
        shouldCloseOnOverlayClick
        screen="desktop"
      >
        <WaHelper onGenerateLink={handleGenerateLink} />
      </Modal>
      <Page height="100vh">
        <Page.Header
          title={`${APPS[appId]?.page?.name}: Start Chat Button`}
          subtitle={`${`Add a ${APPS[appId]?.page?.name} chat button to your website and start chatting with your customers on ${APPS[appId]?.page?.name}.`}`}
          actionsBar={
            <Box gap="SP2">
              <Button
                skin="inverted"
                as="a"
                href={`https://bridget.reviews.certifiedcode.us/${appId}`}
                target="_blank"
              >
                Rate App
              </Button>
              <Button
                prefixIcon={<Icons.Check />}
                onClick={() => {
                  setSettings();
                }}
              >
                Publish Changes
              </Button>
            </Box>
          }
        />
        <Page.Content>
          <Layout>
            {JSON.stringify(config || "{}").includes("3475086396") && (
              <Cell span={12}>
                <SectionHelper
                  title="IMPORTANT: Chat button is not visible on your site"
                  actionText="Edit Contact Info"
                  onAction={scrollToComponent}
                >
                  Before your visitors can see the chat button, you need to edit
                  the contact info and publish your changes.
                </SectionHelper>
              </Cell>
            )}
            <Cell span={9}>
              <Card>
                <Card.Header
                  title={`Show ${APPS[appId]?.page?.name} Chat Button on Live Site`}
                  suffix={
                    <PulseAnimation
                      fluid
                      color="R10"
                      borderRadius={4}
                      active={!isEnabled}
                    >
                      <ToggleSwitch
                        checked={isEnabled}
                        onChange={() => setIsEnabled(!isEnabled)}
                      />
                    </PulseAnimation>
                  }
                />
                <Card.Content>
                  <Layout>
                    <Cell span={12}>
                      <FormField label="Button Position" labelPlacement="left">
                        <Box>
                          <SegmentedToggle
                            onClick={(_, value) => {
                              setConfig({
                                ...config,
                                button: {
                                  ...config.button,
                                  position: value,
                                },
                              });
                            }}
                            selected={
                              config.button.position === "left"
                                ? "left"
                                : "right"
                            }
                          >
                            <SegmentedToggle.Icon
                              value="left"
                              tooltipText="Left"
                            >
                              <Icons.AlignLeft />
                            </SegmentedToggle.Icon>
                            <SegmentedToggle.Icon
                              value="right"
                              tooltipText="Right"
                            >
                              <Icons.AlignRight />
                            </SegmentedToggle.Icon>
                          </SegmentedToggle>
                        </Box>
                      </FormField>
                    </Cell>
                    <Cell span={12}>
                      <FormField label="Button Style" labelPlacement="left">
                        <Box>
                          <SegmentedToggle
                            selected={String(config.button.style)}
                            size="medium"
                            onClick={(_, value) => {
                              setConfig({
                                ...config,
                                button: {
                                  ...config.button,
                                  style: Number(value),
                                },
                              });
                            }}
                          >
                            {Array.from({ length: 7 }, (_, i) => i + 1).map(
                              (i) => (
                                <SegmentedToggle.Button value={String(i)}>
                                  {i}
                                </SegmentedToggle.Button>
                              )
                            )}
                          </SegmentedToggle>
                        </Box>
                      </FormField>
                    </Cell>
                    <Cell span={12}>
                      <FormField
                        label="Button Background Color"
                        labelPlacement="left"
                      >
                        <Box>
                          <ColorInput
                            value={config.button.backgroundColor}
                            onChange={(e) => {
                              setConfig({
                                ...config,
                                button: {
                                  ...config.button,
                                  backgroundColor: e.toString(),
                                },
                              });
                            }}
                          />
                        </Box>
                      </FormField>
                    </Cell>
                    <Cell span={12}>
                      <FormField label="Button Effect" labelPlacement="left">
                        <Box>
                          <SegmentedToggle
                            selected={String(config.button.effect)}
                            size="medium"
                            onClick={(_, value) => {
                              setConfig({
                                ...config,
                                button: {
                                  ...config.button,
                                  effect: Number(value),
                                },
                              });
                            }}
                          >
                            {Array.from({ length: 7 }, (_, i) => i + 1).map(
                              (i) => (
                                <SegmentedToggle.Button value={String(i)}>
                                  {i}
                                </SegmentedToggle.Button>
                              )
                            )}
                          </SegmentedToggle>
                        </Box>
                      </FormField>
                    </Cell>
                    <Cell span={12}>
                      <FormField
                        label="Button Notification Number"
                        labelPlacement="left"
                      >
                        <Box>
                          <Input
                            value={config.button.notificationNumber || ""}
                            onChange={(e) => {
                              setConfig({
                                ...config,
                                button: {
                                  ...config.button,
                                  notificationNumber:
                                    e.target.value.length > 0
                                      ? e.target.value
                                      : false,
                                },
                              });
                            }}
                          />
                        </Box>
                      </FormField>
                    </Cell>
                    <Cell span={12}>
                      <FormField
                        label="Button Speech Bubble"
                        labelPlacement="left"
                      >
                        <Input
                          value={config.button.speechBubble || ""}
                          onChange={(e) => {
                            setConfig({
                              ...config,
                              button: {
                                ...config.button,
                                speechBubble:
                                  e.target.value.length > 0
                                    ? e.target.value
                                    : false,
                              },
                            });
                          }}
                        />
                      </FormField>
                    </Cell>
                    <Cell span={12}>
                      <FormField
                        label="Button Pulse Effect"
                        labelPlacement="left"
                      >
                        <Box>
                          <ToggleSwitch
                            checked={config.button.pulseEffect}
                            onChange={() => {
                              setConfig({
                                ...config,
                                button: {
                                  ...config.button,
                                  pulseEffect: !config.button.pulseEffect,
                                },
                              });
                            }}
                          />
                        </Box>
                      </FormField>
                    </Cell>
                    {config.button.style > 1 && (
                      <Cell span={12}>
                        <FormField label="Button Title" labelPlacement="left">
                          <Input
                            value={config.button.text.title}
                            onChange={(e) => {
                              setConfig({
                                ...config,
                                button: {
                                  ...config.button,
                                  text: {
                                    ...config.button.text,
                                    title: e.target.value,
                                  },
                                },
                              });
                            }}
                          />
                        </FormField>
                      </Cell>
                    )}
                    {config.button.style > 1 && (
                      <Cell span={12}>
                        <FormField
                          label="Button Description"
                          labelPlacement="left"
                        >
                          <Input
                            value={config.button.text.description || ""}
                            onChange={(e) => {
                              setConfig({
                                ...config,
                                button: {
                                  ...config.button,
                                  text: {
                                    ...config.button.text,
                                    description:
                                      e.target.value.length > 0
                                        ? e.target.value
                                        : false,
                                  },
                                },
                              });
                            }}
                          />
                        </FormField>
                      </Cell>
                    )}
                    {config.button.style > 1 && (
                      <Cell span={12}>
                        <FormField label="Button Online" labelPlacement="left">
                          <Input
                            suffix={
                              !canUsePremium && (
                                <Input.IconAffix>
                                  <PremiumHint />
                                </Input.IconAffix>
                              )
                            }
                            disableEditing={!canUsePremium}
                            value={config.button.text.online || ""}
                            onChange={(e) => {
                              setConfig({
                                ...config,
                                button: {
                                  ...config.button,
                                  text: {
                                    ...config.button.text,
                                    online:
                                      e.target.value.length > 0
                                        ? e.target.value
                                        : false,
                                  },
                                },
                              });
                            }}
                          />
                        </FormField>
                      </Cell>
                    )}
                    {config.button.style > 1 && (
                      <Cell span={12}>
                        <FormField label="Button Offline" labelPlacement="left">
                          <Input
                            suffix={
                              !canUsePremium && (
                                <Input.IconAffix>
                                  <PremiumHint />
                                </Input.IconAffix>
                              )
                            }
                            disableEditing={!canUsePremium}
                            value={config.button.text.offline || ""}
                            onChange={(e) => {
                              setConfig({
                                ...config,
                                button: {
                                  ...config.button,
                                  text: {
                                    ...config.button.text,
                                    offline:
                                      e.target.value.length > 0
                                        ? e.target.value
                                        : false,
                                  },
                                },
                              });
                            }}
                          />
                        </FormField>
                      </Cell>
                    )}
                    <Cell span={12}>
                      <Divider />
                    </Cell>
                    <Cell span={12}>
                      <FormField
                        label="Popup Automatic Open"
                        labelPlacement="left"
                      >
                        <Box>
                          <ToggleSwitch
                            checked={config.popup.automaticOpen}
                            onChange={() => {
                              setConfig({
                                ...config,
                                popup: {
                                  ...config.popup,
                                  automaticOpen: !config.popup.automaticOpen,
                                },
                              });
                            }}
                          />
                        </Box>
                      </FormField>
                    </Cell>
                    <Cell span={12}>
                      <FormField
                        label="Popup Outside Click Close Popup"
                        labelPlacement="left"
                      >
                        <Box>
                          <ToggleSwitch
                            checked={config.popup.outsideClickClosePopup}
                            onChange={() => {
                              setConfig({
                                ...config,
                                popup: {
                                  ...config.popup,
                                  outsideClickClosePopup:
                                    !config.popup.outsideClickClosePopup,
                                },
                              });
                            }}
                          />
                        </Box>
                      </FormField>
                    </Cell>
                    <Cell span={12}>
                      <FormField label="Popup Effect">
                        <Box>
                          <SegmentedToggle
                            selected={String(config.popup.effect)}
                            size="small"
                            onClick={(_, value) => {
                              setConfig({
                                ...config,
                                popup: {
                                  ...config.popup,
                                  effect: Number(value),
                                },
                              });
                            }}
                          >
                            {Array.from({ length: 15 }, (_, i) => i + 1).map(
                              (i) => (
                                <SegmentedToggle.Button value={String(i)}>
                                  {i}
                                </SegmentedToggle.Button>
                              )
                            )}
                          </SegmentedToggle>
                        </Box>
                      </FormField>
                    </Cell>
                    <Cell span={12}>
                      <FormField label="Popup Header Background Color">
                        <Box>
                          <ColorInput
                            value={config.popup.header.backgroundColor}
                            onChange={(e) => {
                              setConfig({
                                ...config,
                                popup: {
                                  ...config.popup,
                                  header: {
                                    ...config.popup.header,
                                    backgroundColor: e.toString(),
                                  },
                                },
                              });
                            }}
                          />
                        </Box>
                      </FormField>
                    </Cell>
                    <Cell span={12}>
                      <FormField
                        label="Popup Header Title"
                        labelPlacement="left"
                      >
                        <Input
                          value={config.popup.header.title}
                          onChange={(e) => {
                            setConfig({
                              ...config,
                              popup: {
                                ...config.popup,
                                header: {
                                  ...config.popup.header,
                                  title: e.target.value,
                                },
                              },
                            });
                          }}
                        />
                      </FormField>
                    </Cell>
                    <Cell span={12}>
                      <FormField
                        label="Popup Header Description"
                        labelPlacement="left"
                      >
                        <Input
                          value={config.popup.header.description || ""}
                          onChange={(e) => {
                            setConfig({
                              ...config,
                              popup: {
                                ...config.popup,
                                header: {
                                  ...config.popup.header,
                                  description:
                                    e.target.value.length > 0
                                      ? e.target.value
                                      : false,
                                },
                              },
                            });
                          }}
                        />
                      </FormField>
                    </Cell>

                    <Cell span={12}>
                      <Divider />
                    </Cell>

                    <Cell span={12}>
                      <FormField label="Sound" labelPlacement="left">
                        <Box>
                          <ToggleSwitch
                            checked={config.sound}
                            onChange={() => {
                              setConfig({
                                ...config,
                                sound: !config.sound,
                              });
                            }}
                          />
                        </Box>
                      </FormField>
                    </Cell>
                    <Cell span={12}>
                      <FormField
                        label="Change Browser Title"
                        labelPlacement="left"
                      >
                        <Input
                          suffix={
                            !canUsePremium && (
                              <Input.IconAffix>
                                <PremiumHint />
                              </Input.IconAffix>
                            )
                          }
                          disableEditing={!canUsePremium}
                          value={config.changeBrowserTitle || ""}
                          onChange={(e) => {
                            setConfig({
                              ...config,
                              changeBrowserTitle:
                                e.target.value.length > 0
                                  ? e.target.value
                                  : false,
                            });
                          }}
                        />
                      </FormField>
                    </Cell>
                    <Cell span={12}>
                      <FormField label="Cookie" labelPlacement="left">
                        <Box>
                          <ToggleSwitch
                            checked={config.cookie}
                            onChange={() => {
                              setConfig({
                                ...config,
                                cookie: !config.cookie,
                              });
                            }}
                          />
                        </Box>
                      </FormField>
                    </Cell>
                    <Cell span={12}>
                      <div ref={personList}></div>
                      <SelectableAccordion
                        items={config.popup.persons.map(
                          (person: any, index: any) => {
                            return {
                              title: person.text.title,
                              open: true,
                              content: (
                                <Layout>
                                  <Cell span={12}>
                                    <FormField
                                      label="Avatar Image"
                                      labelPlacement="left"
                                    >
                                      <ImageViewer
                                        imageUrl={person.avatar.src.replace(`<img src="`, "")
                                        .replace(`" alt="">`, "")}
                                        onAddImage={() => {
                                          client.dashboard
                                            .openMediaManager({
                                              category: "IMAGE",
                                            })
                                            .then((chosenMediaItems) => {
                                              const url =
                                                chosenMediaItems?.items[0]?.url;
                                              if (url) {
                                                setConfig({
                                                  ...config,
                                                  popup: {
                                                    ...config.popup,
                                                    persons: [
                                                      ...config.popup.persons.slice(
                                                        0,
                                                        index
                                                      ),
                                                      {
                                                        ...person,
                                                        avatar: {
                                                          ...person.avatar,
                                                          src: `<img src="${url}" style="object-fit: cover; width: 100%; height: 100%;" alt="">`,
                                                        },
                                                      },
                                                      ...config.popup.persons.slice(
                                                        index + 1
                                                      ),
                                                    ],
                                                  },
                                                });
                                              }
                                            });
                                        }}
                                        onUpdateImage={() => {
                                          client.dashboard
                                            .openMediaManager({
                                              category: "IMAGE",
                                            })
                                            .then((chosenMediaItems) => {
                                              const url =
                                                chosenMediaItems?.items[0]?.url;
                                              if (url) {
                                                setConfig({
                                                  ...config,
                                                  popup: {
                                                    ...config.popup,
                                                    persons: [
                                                      ...config.popup.persons.slice(
                                                        0,
                                                        index
                                                      ),
                                                      {
                                                        ...person,
                                                        avatar: {
                                                          ...person.avatar,
                                                          src: `<img src="${url}" style="object-fit: cover; width: 100%; height: 100%;" alt="">`,
                                                        },
                                                      },
                                                      ...config.popup.persons.slice(
                                                        index + 1
                                                      ),
                                                    ],
                                                  },
                                                });
                                              }
                                            });
                                        }}
                                        showRemoveButton
                                        showUpdateButton
                                        width={100}
                                        height={100}
                                        showDownloadButton={false}
                                        onRemoveImage={() => {
                                          setConfig({
                                            ...config,
                                            popup: {
                                              ...config.popup,
                                              persons: [
                                                ...config.popup.persons.slice(
                                                  0,
                                                  index
                                                ),
                                                {
                                                  ...person,
                                                  avatar: {
                                                    ...person.avatar,
                                                    src: "",
                                                  },
                                                },
                                                ...config.popup.persons.slice(
                                                  index + 1
                                                ),
                                              ],
                                            },
                                          });
                                        }}
                                      />
                                    </FormField>
                                  </Cell>
                                  <Cell span={12}>
                                    <FormField
                                      label="Avatar Background Color"
                                      labelPlacement="left"
                                    >
                                      <Box>
                                        <ColorInput
                                          value={person.avatar.backgroundColor}
                                          onChange={(e) => {
                                            setConfig({
                                              ...config,
                                              popup: {
                                                ...config.popup,
                                                persons: [
                                                  ...config.popup.persons.slice(
                                                    0,
                                                    index
                                                  ),
                                                  {
                                                    ...person,
                                                    avatar: {
                                                      ...person.avatar,
                                                      backgroundColor:
                                                        e.toString(),
                                                    },
                                                  },
                                                  ...config.popup.persons.slice(
                                                    index + 1
                                                  ),
                                                ],
                                              },
                                            });
                                          }}
                                        />
                                      </Box>
                                    </FormField>
                                  </Cell>
                                  <Cell span={12}>
                                    <FormField
                                      label="Avatar Online Circle"
                                      labelPlacement="left"
                                    >
                                      <Box>
                                        <ToggleSwitch
                                          checked={person.avatar.onlineCircle}
                                          onChange={() => {
                                            setConfig({
                                              ...config,
                                              popup: {
                                                ...config.popup,
                                                persons: [
                                                  ...config.popup.persons.slice(
                                                    0,
                                                    index
                                                  ),
                                                  {
                                                    ...person,
                                                    avatar: {
                                                      ...person.avatar,
                                                      onlineCircle:
                                                        !person.avatar
                                                          .onlineCircle,
                                                    },
                                                  },
                                                  ...config.popup.persons.slice(
                                                    index + 1
                                                  ),
                                                ],
                                              },
                                            });
                                          }}
                                        />
                                      </Box>
                                    </FormField>
                                  </Cell>
                                  <Cell span={12}>
                                    <Divider />
                                  </Cell>
                                  <Cell span={12}>
                                    <FormField
                                      label="Title"
                                      labelPlacement="left"
                                    >
                                      <Input
                                        value={person.text.title}
                                        onChange={(e) => {
                                          setConfig({
                                            ...config,
                                            popup: {
                                              ...config.popup,
                                              persons: [
                                                ...config.popup.persons.slice(
                                                  0,
                                                  index
                                                ),
                                                {
                                                  ...person,
                                                  text: {
                                                    ...person.text,
                                                    title: e.target.value,
                                                  },
                                                },
                                                ...config.popup.persons.slice(
                                                  index + 1
                                                ),
                                              ],
                                            },
                                          });
                                        }}
                                      ></Input>
                                    </FormField>
                                  </Cell>
                                  <Cell span={12}>
                                    <FormField
                                      label="Description"
                                      labelPlacement="left"
                                    >
                                      <Input
                                        value={person.text.description}
                                        onChange={(e) => {
                                          setConfig({
                                            ...config,
                                            popup: {
                                              ...config.popup,
                                              persons: [
                                                ...config.popup.persons.slice(
                                                  0,
                                                  index
                                                ),
                                                {
                                                  ...person,
                                                  text: {
                                                    ...person.text,
                                                    description: e.target.value,
                                                  },
                                                },
                                                ...config.popup.persons.slice(
                                                  index + 1
                                                ),
                                              ],
                                            },
                                          });
                                        }}
                                      ></Input>
                                    </FormField>
                                  </Cell>
                                  <Cell span={12}>
                                    <FormField
                                      label="Online"
                                      labelPlacement="left"
                                    >
                                      <Input
                                        suffix={
                                          !canUsePremium && (
                                            <Input.IconAffix>
                                              <PremiumHint />
                                            </Input.IconAffix>
                                          )
                                        }
                                        disableEditing={!canUsePremium}
                                        value={person.text.online}
                                        onChange={(e) => {
                                          setConfig({
                                            ...config,
                                            popup: {
                                              ...config.popup,
                                              persons: [
                                                ...config.popup.persons.slice(
                                                  0,
                                                  index
                                                ),
                                                {
                                                  ...person,
                                                  text: {
                                                    ...person.text,
                                                    online: e.target.value,
                                                  },
                                                },
                                                ...config.popup.persons.slice(
                                                  index + 1
                                                ),
                                              ],
                                            },
                                          });
                                        }}
                                      ></Input>
                                    </FormField>
                                  </Cell>
                                  <Cell span={12}>
                                    <FormField
                                      label="Offline"
                                      labelPlacement="left"
                                    >
                                      <Input
                                        suffix={
                                          !canUsePremium && (
                                            <Input.IconAffix>
                                              <PremiumHint />
                                            </Input.IconAffix>
                                          )
                                        }
                                        disableEditing={!canUsePremium}
                                        value={person.text.offline}
                                        onChange={(e) => {
                                          setConfig({
                                            ...config,
                                            popup: {
                                              ...config.popup,
                                              persons: [
                                                ...config.popup.persons.slice(
                                                  0,
                                                  index
                                                ),
                                                {
                                                  ...person,
                                                  text: {
                                                    ...person.text,
                                                    offline: e.target.value,
                                                  },
                                                },
                                                ...config.popup.persons.slice(
                                                  index + 1
                                                ),
                                              ],
                                            },
                                          });
                                        }}
                                      ></Input>
                                    </FormField>
                                  </Cell>
                                  <Cell span={12}>
                                    <FormField
                                      label="Desktop Link"
                                      suffix={
                                        appId ===
                                          "463fa61d-e677-4847-8749-b708786d1176" && (
                                          <TextButton
                                            size="small"
                                            prefixIcon={<Icons.AIFilledSmall />}
                                            onClick={() => {
                                              setIsShowWaHelper(true);
                                              setSelectedPersonIndex(index);
                                            }}
                                          >
                                            Generate WhatsApp Link
                                          </TextButton>
                                        )
                                      }
                                    >
                                      <PulseAnimation
                                        fluid
                                        active={showPulseOnLink}
                                        color="B10"
                                        borderRadius={4}
                                      >
                                        <Input
                                          value={person.link.desktop}
                                          onChange={(e) => {
                                            setConfig({
                                              ...config,
                                              popup: {
                                                ...config.popup,
                                                persons: [
                                                  ...config.popup.persons.slice(
                                                    0,
                                                    index
                                                  ),
                                                  {
                                                    ...person,
                                                    link: {
                                                      ...person.link,
                                                      desktop: e.target.value,
                                                    },
                                                  },
                                                  ...config.popup.persons.slice(
                                                    index + 1
                                                  ),
                                                ],
                                              },
                                            });
                                          }}
                                        ></Input>
                                      </PulseAnimation>
                                    </FormField>
                                  </Cell>
                                  <Cell span={12}>
                                    <FormField label="Mobile Link">
                                      <PulseAnimation
                                        fluid
                                        active={showPulseOnLink}
                                        color="B10"
                                        borderRadius={4}
                                      >
                                        <Input
                                          value={person.link.mobile}
                                          onChange={(e) => {
                                            setConfig({
                                              ...config,
                                              popup: {
                                                ...config.popup,
                                                persons: [
                                                  ...config.popup.persons.slice(
                                                    0,
                                                    index
                                                  ),
                                                  {
                                                    ...person,
                                                    link: {
                                                      ...person.link,
                                                      mobile: e.target.value,
                                                    },
                                                  },
                                                  ...config.popup.persons.slice(
                                                    index + 1
                                                  ),
                                                ],
                                              },
                                            });
                                          }}
                                        ></Input>
                                      </PulseAnimation>
                                    </FormField>
                                  </Cell>
                                  <Cell span={12}>
                                    <Divider />
                                  </Cell>
                                  <Cell span={12}>
                                    <FormField label="Online Day">
                                      {Object.keys(person.onlineDay).map(
                                        (day: any) => (
                                          <Box>
                                            <ToggleSwitch
                                              // @ts-ignore
                                              checked={
                                                person.onlineDay[day] !== false
                                              }
                                              onChange={(event) => {
                                                const isToggleOn =
                                                  event.target.checked;
                                                setConfig({
                                                  ...config,
                                                  popup: {
                                                    ...config.popup,
                                                    persons: [
                                                      ...config.popup.persons.slice(
                                                        0,
                                                        index
                                                      ),
                                                      {
                                                        ...person,
                                                        onlineDay: {
                                                          ...person.onlineDay,
                                                          [day]: isToggleOn
                                                            ? "00:00-23:59"
                                                            : false,
                                                        },
                                                      },
                                                      ...config.popup.persons.slice(
                                                        index + 1
                                                      ),
                                                    ],
                                                  },
                                                });
                                              }}
                                            />
                                            <Text>{day}</Text>
                                            {
                                              // @ts-ignore
                                              person.onlineDay[day] && (
                                                <Box>
                                                  <FormField
                                                    label="Online Time"
                                                    labelPlacement="left"
                                                  >
                                                    <Box>
                                                      <Input
                                                        suffix={
                                                          !canUsePremium && (
                                                            <Input.IconAffix>
                                                              <PremiumHint />
                                                            </Input.IconAffix>
                                                          )
                                                        }
                                                        disableEditing={
                                                          !canUsePremium
                                                        }
                                                        value={
                                                          person.onlineDay[day]
                                                        }
                                                        onChange={(e) => {
                                                          var toUpdate = config;

                                                          toUpdate.popup.persons[
                                                            index
                                                          ].onlineDay[day] =
                                                            e.target.value;

                                                          console.log(
                                                            toUpdate.popup
                                                              .persons[index]
                                                              .onlineDay[day]
                                                          );

                                                          setConfig(toUpdate);
                                                        }}
                                                      />
                                                    </Box>
                                                  </FormField>
                                                </Box>
                                              )
                                            }
                                          </Box>
                                        )
                                      )}
                                    </FormField>
                                  </Cell>
                                  <Cell span={12}>
                                    <Divider />
                                  </Cell>
                                  <Cell span={12}>
                                    <Button
                                      skin="destructive"
                                      onClick={() => {
                                        setConfig({
                                          ...config,
                                          popup: {
                                            ...config.popup,
                                            persons:
                                              config.popup.persons.filter(
                                                (person: any, i: any) =>
                                                  i !== index
                                              ),
                                          },
                                        });
                                      }}
                                    >
                                      Remove Person
                                    </Button>
                                  </Cell>
                                </Layout>
                              ),
                            };
                          }
                        )}
                      />
                    </Cell>
                    <Cell>
                      <Button
                        prefixIcon={<Icons.Add />}
                        onClick={() => {
                          // copy the last
                          const lastPerson =
                            config.popup.persons[
                              config.popup.persons.length - 1
                            ];
                          setConfig({
                            ...config,
                            popup: {
                              ...config.popup,
                              persons: [...config.popup.persons, SAMPLE_PERSON],
                            },
                          });
                        }}
                      >
                        Add Person
                      </Button>
                    </Cell>
                  </Layout>
                </Card.Content>
              </Card>
            </Cell>
            <Cell span={3}>
              <Card>
                <Card.Content>
                  <Layout>
                    <Cell>
                      <Button
                        prefixIcon={<Icons.PremiumFilled />}
                        skin="premium"
                        as="a"
                        href={`https://www.wix.com/apps/upgrade/${appId}?appInstanceId=${
                          (instanceData as any)?.instance?.instanceId
                        }`}
                        target="_blank"
                      >
                        Upgrade Now
                      </Button>
                    </Cell>
                    <Cell span={12}>
                      <CircularProgressBar
                        label={
                          isUpgraded
                            ? "You're on Premium"
                            : trialRemainingDate <= 0
                            ? "Your Pro trial has ended. Premium features locked & cannot be edited."
                            : `Pro trial ends in ${trialRemainingDate} days`
                        }
                        skin={isUpgraded ? "standard" : "premium"}
                        showProgressIndication
                        error={isUpgraded ? false : trialRemainingDate <= 0}
                        errorMessage={
                          trialRemainingDate <= 0
                            ? "Your Pro trial has ended. Upgrade to Pro to continue using premium features."
                            : undefined
                        }
                        errorLabel={
                          trialRemainingDate <= 0 ? "Trial Ended" : undefined
                        }
                        value={isUpgraded ? 100 : 1 - trialRemainingDate / 30}
                      />
                    </Cell>
                  </Layout>
                </Card.Content>
              </Card>
              <Page.Section title="Add More Start Chat Buttons" showDivider />
              <Layout>
                {Object.keys(APPS)
                  .filter(
                    (v: string) => v !== appId && APPS[v]?.isPrivate !== true
                  )
                  .map((appId: string) => (
                    <Cell span={12}>
                      <CardGalleryItem
                        aspectRatio={1}
                        title={APPS[appId]?.page?.name}
                        imagePlacement="side"
                        backgroundImageUrl={APPS[appId]?.icon}
                        primaryActionProps={{
                          label: "Install",
                          disabled: !!APPS[appId]?.isPrivate,
                          onClick: () => {
                            window.open(
                              `https://go.certifiedcode.global/get/${appId}/${
                                (instanceData as any)?.site?.siteId
                              }`
                            );
                          },
                        }}
                      />
                    </Cell>
                  ))}
              </Layout>
            </Cell>
          </Layout>
        </Page.Content>
      </Page>
    </WixDesignSystemProvider>
  );
}

export default App;
